.segmented-control {
  position: relative;
  display: inline-block;
  border: 1px solid currentColor;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  overflow: hidden;

  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -o-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-tap-highlight-color: transparent;
}
.segmented-control > input {
  position: absolute;
  left: -10000px;
  opacity: 0;
}
.segmented-control > input[type="checkbox"] ~ label {
  -moz-transition-duration: 0;
  -o-transition-duration: 0;
  -webkit-transition-duration: 0;
  transition-duration: 0;
}
.segmented-control > input[type="checkbox"] ~ label:before {
  opacity: 0;
}
.segmented-control > input:disabled:nth-child(1) ~ label:nth-of-type(1) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(1):checked ~ label:nth-of-type(1):after,
.segmented-control > input:nth-child(1):checked ~ label:nth-of-type(1):before {
  opacity: 1;
}
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(1):after,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(1):before,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(1)
  ~ label:after,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(1)
  ~ label:before {
  left: 0%;
}
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(2):after,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(2):before,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(2)
  ~ label:after,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(2)
  ~ label:before {
  left: 0%;
}
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(3):after,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(3):before,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(3)
  ~ label:after,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(3)
  ~ label:before {
  left: 0%;
}
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(4):after,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(4):before,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(4)
  ~ label:after,
.segmented-control
  > input:nth-child(1):checked
  ~ label:first-of-type:nth-last-of-type(4)
  ~ label:before {
  left: 0%;
}
.segmented-control > input:disabled:nth-child(2) ~ label:nth-of-type(2) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(2):checked ~ label:nth-of-type(2):after,
.segmented-control > input:nth-child(2):checked ~ label:nth-of-type(2):before {
  opacity: 1;
}
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(2):after,
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(2):before,
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(2)
  ~ label:after,
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(2)
  ~ label:before {
  left: 50%;
}
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(3):after,
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(3):before,
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(3)
  ~ label:after,
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(3)
  ~ label:before {
  left: 33.33333%;
}
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(4):after,
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(4):before,
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(4)
  ~ label:after,
.segmented-control
  > input:nth-child(2):checked
  ~ label:first-of-type:nth-last-of-type(4)
  ~ label:before {
  left: 25%;
}
.segmented-control > input:disabled:nth-child(3) ~ label:nth-of-type(3) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(3):checked ~ label:nth-of-type(3):after,
.segmented-control > input:nth-child(3):checked ~ label:nth-of-type(3):before {
  opacity: 1;
}
.segmented-control
  > input:nth-child(3):checked
  ~ label:first-of-type:nth-last-of-type(3):after,
.segmented-control
  > input:nth-child(3):checked
  ~ label:first-of-type:nth-last-of-type(3):before,
.segmented-control
  > input:nth-child(3):checked
  ~ label:first-of-type:nth-last-of-type(3)
  ~ label:after,
.segmented-control
  > input:nth-child(3):checked
  ~ label:first-of-type:nth-last-of-type(3)
  ~ label:before {
  left: 66.66667%;
}
.segmented-control
  > input:nth-child(3):checked
  ~ label:first-of-type:nth-last-of-type(4):after,
.segmented-control
  > input:nth-child(3):checked
  ~ label:first-of-type:nth-last-of-type(4):before,
.segmented-control
  > input:nth-child(3):checked
  ~ label:first-of-type:nth-last-of-type(4)
  ~ label:after,
.segmented-control
  > input:nth-child(3):checked
  ~ label:first-of-type:nth-last-of-type(4)
  ~ label:before {
  left: 50%;
}
.segmented-control > input:disabled:nth-child(4) ~ label:nth-of-type(4) {
  opacity: 0.3;
  cursor: not-allowed;
}
.segmented-control > input:nth-child(4):checked ~ label:nth-of-type(4):after,
.segmented-control > input:nth-child(4):checked ~ label:nth-of-type(4):before {
  opacity: 1;
}
.segmented-control
  > input:nth-child(4):checked
  ~ label:first-of-type:nth-last-of-type(4):after,
.segmented-control
  > input:nth-child(4):checked
  ~ label:first-of-type:nth-last-of-type(4):before,
.segmented-control
  > input:nth-child(4):checked
  ~ label:first-of-type:nth-last-of-type(4)
  ~ label:after,
.segmented-control
  > input:nth-child(4):checked
  ~ label:first-of-type:nth-last-of-type(4)
  ~ label:before {
  left: 75%;
}
.segmented-control > label {
  display: inline-block;
  padding: 0.3em 0.4em 0 0.4em;
  cursor: pointer;
  float: left;
  line-height: 2em;
  font-style: inherit;
  font-weight: inherit;
  text-decoration: inherit;
  -moz-transition-property: none;
  -o-transition-property: none;
  -webkit-transition-property: none;
  transition-property: none;
  -moz-transition-duration: inherit;
  -o-transition-duration: inherit;
  -webkit-transition-duration: inherit;
  transition-duration: inherit;
  -moz-transition-timing-function: inherit;
  -o-transition-timing-function: inherit;
  -webkit-transition-timing-function: inherit;
  transition-timing-function: inherit;
}
.segmented-control > label:before,
.segmented-control > label:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.segmented-control > label:after {
  color: black;
  content: attr(data-value);
  text-align: center;
  padding: inherit;
  z-index: 10;
  font-style: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  opacity: 0;
  -moz-transition-property: opacity, left;
  -o-transition-property: opacity, left;
  -webkit-transition-property: opacity, left;
  transition-property: opacity, left;
  -moz-transition-duration: inherit;
  -o-transition-duration: inherit;
  -webkit-transition-duration: inherit;
  transition-duration: inherit;
  -moz-transition-timing-function: inherit;
  -o-transition-timing-function: inherit;
  -webkit-transition-timing-function: inherit;
  transition-timing-function: inherit;
}
.segmented-control > label:after,
.segmented-control > label {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.segmented-control > label:before {
  content: "";
  color: inherit;
  background: currentColor;
  -moz-transition-property: left;
  -o-transition-property: left;
  -webkit-transition-property: left;
  transition-property: left;
  -moz-transition-duration: inherit;
  -o-transition-duration: inherit;
  -webkit-transition-duration: inherit;
  transition-duration: inherit;
  -moz-transition-timing-function: inherit;
  -o-transition-timing-function: inherit;
  -webkit-transition-timing-function: inherit;
  transition-timing-function: inherit;
}
.segmented-control > label:first-of-type:nth-last-of-type(1),
.segmented-control > label:first-of-type:nth-last-of-type(1):after,
.segmented-control > label:first-of-type:nth-last-of-type(1):before,
.segmented-control > label:first-of-type:nth-last-of-type(1) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(1) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(1) ~ label:before {
  width: 100%;
}
.segmented-control > label:first-of-type:nth-last-of-type(2),
.segmented-control > label:first-of-type:nth-last-of-type(2):after,
.segmented-control > label:first-of-type:nth-last-of-type(2):before,
.segmented-control > label:first-of-type:nth-last-of-type(2) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(2) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(2) ~ label:before {
  width: 50%;
}
.segmented-control > label:first-of-type:nth-last-of-type(3),
.segmented-control > label:first-of-type:nth-last-of-type(3):after,
.segmented-control > label:first-of-type:nth-last-of-type(3):before,
.segmented-control > label:first-of-type:nth-last-of-type(3) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(3) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(3) ~ label:before {
  width: 33.33333%;
}
.segmented-control > label:first-of-type:nth-last-of-type(4),
.segmented-control > label:first-of-type:nth-last-of-type(4):after,
.segmented-control > label:first-of-type:nth-last-of-type(4):before,
.segmented-control > label:first-of-type:nth-last-of-type(4) ~ label,
.segmented-control > label:first-of-type:nth-last-of-type(4) ~ label:after,
.segmented-control > label:first-of-type:nth-last-of-type(4) ~ label:before {
  width: 25%;
}
.segmented-control.italic,
.segmented-control .italic {
  font-style: italic;
}
.segmented-control.bold,
.segmented-control .bold {
  font-weight: bold;
}
.segmented-control.underline,
.segmented-control .underline {
  text-decoration: underline;
}
.segmented-control.line-through,
.segmented-control .line-through {
  text-decoration: line-through;
}

html {
  font-size: 14px;
  font-family: "Montserrat";
  font-weight: 400;
  letter-spacing: 0.04em;
  color: #c2c8cd;
  cursor: default;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  min-height: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  display: -webkit-flex;
  display: flex;
}

a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.container {
  //   margin: auto;
  max-width: 700px;
  min-width: 200px;
  text-align: center;
  padding: 0 6em;
}
