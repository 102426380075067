:root {
  // Colors
  --color-white: #ffffff;
  --color-violet-52: #ea9611;
  --color-blue-14: hsl(219, 29%, 14%);
  --color-blue-35: hsl(217, 19%, 35%);
  --color-gray-81: hsl(0, 0%, 81%);
  --color-gray-95: hsl(210, 46%, 95%);

  // Font Weights
  --weight-mid: 500;
  --weight-semi-bold: 600;

  // Design elements
  --transition: 300ms ease-in-out;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body,
#root,
.App {
  height: 100%;
  background-color: #0e1111;
}

body {
  margin: 0;
  font-size: 1.3rem;
  letter-spacing: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: var(--color-gray-95);
  font-family: "Montserrat";
}

h2,
h3,
p {
  margin: 0;
}

.container {
  padding: 7.1rem 2.4rem;
  transition: var(--transition) padding;
  @media (min-width: 768px) {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
  }
  @media (min-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-height: 900px) {
    padding-top: 16.4rem;
    padding-bottom: 16.4rem;
  }
}

// Font specs
.t-h2 {
  font-size: 1.5rem;
  line-height: 1.8rem;
  letter-spacing: 0.047px;
  font-weight: var(--weight-semi-bold);
}

.t-body {
  font-size: 1rem;
  line-height: 1.3rem;
  letter-spacing: 0.03px;
  font-weight: var(--weight-mid);
}

.t-name {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: var(--weight-mid);
}

.t-title {
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: var(--weight-mid);
}

// text color
.t-white {
  color: var(--color-white);
}

.t-blue {
  color: var(--color-blue-35);
}

.t-light-gray {
  color: var(--color-gray-95);
}

// text opacity
.t-op50 {
  opacity: 0.5;
}

.t-op70 {
  opacity: 0.7;
}

.bm-overlay {
  //
}

.bm-burger-button {
  position: absolute;
  top: 8px;
  right: 8px;
}

.bm-menu {
  // margin-top: -30px;
  background: #ea9611 !important;
}

.bm-item {
  border: none;
  border-bottom-color: #ea9611;
}

.bm-item:focus {
  outline: none;
}

#react-burger-menu-btn {
  height: 30px !important;
}

.yearly-toggle .react-toggle-track {
  background-color: #ea9611;
}
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #ea9611;
}
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #ea9611;
}

.two-lines {
  display: block; /* or inline-block */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}
