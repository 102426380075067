.testimonials-grid {
  display: grid;
  row-gap: 2.4rem;
  @media (min-width: 768px) {
    margin: 0 auto;
    column-gap: 3rem;
    max-width: 90rem;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  .testimonial {
    border-radius: 0.8rem;
    padding: 2rem 2.6rem 2.6rem;
    transition: var(--transition) transform;
    box-shadow: 4rem 6rem 5rem -4.7rem rgba(72, 85, 106, 0.25);

    // testimonial bg colors
    &--violet {
      background-repeat: no-repeat;
      background-position: top right 2.4rem;
      background-color: var(--color-violet-52);
      background-image: url(../assets/bg-pattern-quotation.svg);
      @media (min-width: 768px) {
        background-position: top right 8rem;
      }
    }

    &--gray {
      background-color: var(--color-blue-35);
    }

    &--white {
      background-color: var(--color-white);
    }

    &--blue {
      background-color: var(--color-blue-14);
    }

    // profile
    &__profile {
      display: grid;
      column-gap: 1.5rem;
      align-items: center;
      grid-template-columns: 3rem auto;

      // avatar
      &__avatar {
        z-index: 2;
        grid-row: 1/3;
        width: 2.8rem;
        height: 2.8rem;
        grid-column: 1/2;
        border-radius: 50%;
        position: relative;
        align-items: center;
        display: inline-flex;
        justify-content: center;
        &::after {
          content: "";
          top: 50%;
          left: 50%;
          z-index: 1;
          width: 3.2rem;
          height: 3.2rem;
          position: absolute;
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }
        &--violet::after {
          background-color: #000;
        }
        &--blue::after {
          background-color: var(--color-violet-52);
        }
        &--gray::after {
          background-color: var(--color-violet-52);
        }
        &--white::after {
          background-color: var(--color-violet-52);
        }

        img {
          z-index: 2;
          width: 3.3rem;
          height: 3.3rem;
          border-radius: 50%;
          position: relative;
        }
      }
      &__name {
        grid-row: 1/2;
        grid-column: 2/3;
        margin-bottom: 0.2rem;
      }
      &__title {
        grid-row: 2/3;
        grid-column: 2/3;
      }
    }

    // testimonial quotes
    &__after {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    // layouts on tablet
    @media (min-width: 768px) {
      &--1 {
        grid-row: 1/3;
        grid-column: 2/3;
      }
      &--2 {
        grid-row: 1/2;
        grid-column: 1/2;
      }
      &--3 {
        grid-row: 2/3;
        grid-column: 1/2;
      }
      &--4 {
        grid-row: 3/4;
        grid-column: 1/3;
      }
      &--5 {
        grid-row: 4/5;
        grid-column: 1/3;
      }
    }
    @media (min-width: 1200px) {
      &--1 {
        grid-row: 1/2;
        grid-column: 1/3;
        animation-name: slideInUp;
        animation-duration: 900ms;
        animation-timing-function: ease-in-out;
        &:hover {
          transform: translateY(-0.5rem);
        }
      }
      &--2 {
        grid-row: 1/2;
        grid-column: 3/4;
        animation-name: slideInUp;
        animation-duration: 900ms;
        animation-timing-function: ease-in-out;
        &:hover {
          transform: translateY(-0.5rem);
        }
      }
      &--3 {
        grid-row: 2/3;
        grid-column: 1/2;
        padding-bottom: 2.6rem;
        animation-name: slideInDown;
        animation-duration: 700ms;
        animation-timing-function: ease-in-out;
        &:hover {
          transform: translateY(-0.5rem);
        }
        .testimonial__after {
          margin-bottom: 2.4rem;
        }
      }
      &--4 {
        grid-row: 2/3;
        grid-column: 2/4;
        padding-right: 3.46rem;
        padding-bottom: 2.6rem;
        animation-name: slideInDown;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        &:hover {
          transform: translateY(-0.5rem);
        }
        .testimonial__after {
          margin-bottom: 2.4rem;
        }
      }
      &--5 {
        grid-row: 1/3;
        grid-column: 4/5;
        padding-bottom: 2.6rem;
        animation-name: slideInRight;
        animation-duration: 800ms;
        animation-timing-function: ease-in-out;
        &:hover {
          transform: translateX(-0.5rem);
        }
        .testimonial__after {
          margin-bottom: 2.4rem;
        }
      }
    }
  }
}

@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  from {
    opacity: 0;
    transform: translateY(2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
