/* CSS RESET */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  /* list-style: none; */
  text-align: left;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  background-color: #0e1111;

  color: #fff !important;
  padding: 0;
  margin: 0;

  font-family: "Montserrat";
}

input {
  border: 0;
  outline: 0;
}
textarea:focus,
input:focus {
  outline: none !important;
}

::placeholder {
  color: #ea9611;
}

.logo-shadow {
  -webkit-filter: drop-shadow(2px 2px 2px rgba(256, 256, 256, 1));
  filter: drop-shadow(2px 2px 2px rgba(256, 256, 256, 1));
}

body {
  background: rgba(10, 10, 10, 1);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.globe-container {
  padding: 50px;
  margin-top: 50px;
}
.sphere {
  position: relative;
  margin: 0 auto;
  display: block;
  height: 400px; /* 300*/
  width: 400px; /* 300*/
  box-shadow: 0px 0px 20px 0px #ea9611;
  background: rgba(55, 62, 72, 1);
  /* background: #0000; */

  border-radius: 200px; /*150*/
  transform: rotate(-23.5deg);
}
.layer {
  position: absolute;
  height: 400px; /* 300*/
  width: 400px; /* 300*/
  border-radius: 200px; /*150*/
  overflow: hidden;
}
.ambience {
  width: 266px; /*200*/
  height: 266px; /*200*/
  box-shadow: 0px 0px 80px 80px rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 133px; /*100*/
}
.edge {
  height: 400px; /* 300*/
  width: 400px; /* 300*/
  border-radius: 200px; /*150*/
  box-shadow: inset 0px 0px 100px 20px rgba(0, 0, 0, 0.4);
}
.ocean-glare {
  height: 40px;
  width: 40px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 40px 40px rgba(255, 255, 255, 1);
  border-radius: 200px; /*150*/
  position: absolute;
  left: 80px;
  top: 100px;
  transform: rotate(-0deg);
}

.glare {
  height: 20px;
  width: 20px;
  background: rgba(200, 200, 150, 0.7);
  box-shadow: 0px 0px 30px 30px rgba(200, 200, 150, 0.7);
  border-radius: 200px; /*150*/
  position: absolute;
  left: 80px;
  top: 100px;
}

.darkside {
  box-shadow: inset -60px -10px 80px 0px rgba(0, 0, 0, 0.9);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.land {
  animation: rotate 6s linear infinite;
  background-size: 800px 400px; /* 300*/
  background-image: url("./assets/world.svg");
}

.clouds {
  animation: rotate 3s linear infinite;
  background-size: 800px 400px; /* 300*/
  background-image: url("./assets/clouds-orange.svg");
  /* background-image: url("./assets/clouds-white.svg"); */
  opacity: 0.6;
}

@keyframes rotate {
  from {
    background-position-x: 0px;
  }
  to {
    background-position-x: 800px;
  }
}

.form-control {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #fff !important;
  border-radius: 0px !important;
}
.rbt-input {
  background: transparent !important;
  border: none;
}

.rbt-input-multi {
  background: transparent !important;
  border: none;
  border-bottom: 1px solid #fff;
}

.rbt-input-main {
  width: 30px !important;
}

.rbt-input-wrapper {
  display: flex;
  flex-direction: row;
}

.rbt-token-remove-button {
  color: #fff;
  margin-left: 8px;
}
.mcc-token {
  background: #ea9611;
  border-radius: 7px;
  overflow: hidden;
  padding: 8px 20px 8px 12px;
  color: #fff;
}
h1,
h2 {
  font-size: 76px;
  display: block;
  text-align: center;
  margin-bottom: 150px;
}

h1 {
  margin: 0;
  padding: 50px 0 0 0;
  pointer-events: none;
}

h3 {
  font-size: 88px;
}

.hero {
  top: -40vh;
  position: relative;
  height: 40vh;
}

.down-arrow {
  position: absolute;
  bottom: -300px;
  left: 50%;
  margin-left: -22px;
  font-size: 100px;
  color: #ea9611;
}

.intro {
  margin: 0;
}

.halfFrame {
  top: 0;
  position: fixed;
  height: 50vh;
  border: 1px solid black;
  opacity: 0.5;
  width: 100%;
}

/* pin */
.pin {
  position: relative;
  height: 100vh;
  width: 100%;
  top: 0;
}

.pin-pin {
  position: fixed;
}

.pin-unpin {
  position: absolute;
  bottom: 0;
  top: auto;
}

.pin-txt {
  position: absolute;
  top: 0;
  margin: 0;
  text-align: center;
  width: 100%;
}

.pin-cont {
  position: relative;
  height: 1400px;
}

.pin-cont-proxy {
  position: absolute;
  height: 100%;
}

/* slide, reveal */
.slide-proxy {
  height: 200px;
}

.slide,
.reveal {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  background: blue;
  z-index: 5;
}

.reveal {
  overflow: hidden;
}

.slide-txt,
.reveal-txt {
  position: absolute;
  top: 50%;
  margin: -70px 0 0 0;
  width: 100%;
  text-align: center;
}

.slide-spacer {
  height: 600px;
}

/* unpin */
.unpin-txt {
  position: absolute;
  top: 100%;
  margin: 10px;
  width: 100%;
  text-align: center;
  z-index: 5;
}

/* parallax */

.parallax-cont {
  position: relative;
  overflow: hidden;
  height: 300px;
}

.parallax-cont-proxy {
  position: absolute;
  height: 100%;
}

.parallax-shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 300px;
  z-index: 3;
  -webkit-box-shadow: inset 0px 0px 35px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0px 0px 35px 0px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px 0px 35px 0px rgba(0, 0, 0, 0.75);
}

.parallax-img {
  /* background: url("bg.jpg"); */
  height: 800px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.parallax-txt {
  position: absolute;
  z-index: 2;
  left: 50%;
  margin-left: -180px;
}

/* misc */
.fade1 {
  opacity: 0.5;
}
.fade2 {
  opacity: 0.3;
}

.hide {
  display: none;
}

.spacer50 {
  height: 50vh;
}

.spacer10 {
  height: 10vh;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.center {
  display: block;
  margin: auto;
  text-align: center;
}

.text-center {
  text-align: center;
}
